@import "~bootswatch/dist/quartz/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/quartz/bootswatch";

html, body {
  font-size: 11px;
  height: 100%;
}

#logoImage {
  height: 36px;
}

.avatar {
  height: 64px;
  border-radius: 50%;
}

.expProgress {
  height: 5px;
}

.wholePage {
  width: 100vw;
  height: 100vh;
}

.form-control {
  padding: 0.75rem 0.5rem;
}